import { useState, useEffect } from 'react';
import webapp from '@coinweb/webapp-library';
import { useSearchParams, useNavigate } from 'react-router-dom';
import getPrivKey from 'utils/getPrivKey';
import { usePod } from 'hooks/usePod';
import { Box, Button, Typography } from '@mui/material';
import ModalWebapp from 'modules/tokenization/components/ModalWebapp/ModalWebapp';
import LinkMintLogo from 'images/linkMintLogo.svg';
import Logo from 'images/logo_login.svg';
import QRCode from 'react-qr-code';
import { REACT_APP_FEATURE_FLAG, REACT_APP_DEVNET } from '../../../conf';
import styles from './Login.module.scss';
import { CenteredBox } from '../components/Containers/Box';
// import { LoginQrScanner } from '../components/LoginQrScanner/LoginQrScanner';

type LoginButtonsProps = {
  qr: any;
  onLogin: () => void;
};

const url = 'coinweb-app://open?auth=';

const LoginButtons = (props: LoginButtonsProps) => {
  const encodedString = Buffer.from(props.qr).toString('base64');
  const redirectUrl = url + encodedString;

  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  if (isMobileDevice)
    return (
      <CenteredBox className={styles.buttonWrapper}>
        <Button
          className={styles.loginQrColorText}
          onClick={props.onLogin}
          sx={{ margin: 'auto 10px' }}
        >
          Scan QR code to login
        </Button>
        <a
          className={styles.walletLink}
          style={{ margin: 'auto 10px' }}
          href={redirectUrl}
        >
          <Button className={styles.loginWalletColorText}>
            Login with Coinweb Wallet
          </Button>
        </a>
      </CenteredBox>
    );

  return (
    <Button
      className={styles.loginQrColorText}
      onClick={props.onLogin}
      sx={{ margin: '120px auto auto auto' }}
    >
      Scan QR code to login
    </Button>
  );
};

export const LoginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openWebapp, setOpenWebapp] = useState(false);
  const [qrJson, setQrJson] = useState('');
  const [login, setLogin] = useState<boolean>(false);
  // const [, setWalletPubKey] = useWalletFromParams();
  const [subscription, setPodSubscription] = usePod();

  const navigate = useNavigate();
  const handleOpenWebapp = () => setOpenWebapp(true);
  const handleCloseWebapp = () => setOpenWebapp(false);

  useEffect(() => {
    const privkeyParam = searchParams.get('key');
    let pubKey = '';
    let privKey = '';

    if (privkeyParam !== null) {
      const key = getPrivKey(privkeyParam);
      pubKey = key.pubKey;
      privKey = key.privKey;
    } else {
      try {
        const key = getPrivKey(localStorage.getItem('privKey'));
        pubKey = key.pubKey;
        privKey = key.privKey;
      } catch {
        const key = webapp.create_webapp_keys();
        pubKey = key.pubKey;
        privKey = key.privKey;
      }
    }
    localStorage.setItem(
      'privKey',
      Buffer.from(JSON.stringify(privKey)).toString('base64'),
    );
    setPodSubscription(pubKey, privKey);
    const qr = webapp.create_webapp_qr(pubKey);
    setQrJson(qr);
  }, [setSearchParams, setQrJson, searchParams, setPodSubscription]);

  useEffect(() => {
    if (subscription) {
      const tokenParam = searchParams.get('token');
      const privKey = getPrivKey(localStorage.getItem('privKey'));
      subscription.subscribe((x: any) => {
        const subscriptionType = x.data.subscribeWebapp.type;
        if (subscriptionType === 'DATA') {
          try {
            const key = webapp.decode(
              privKey.privKey,
              x.data.subscribeWebapp.payload[0],
            );
            if (tokenParam !== null) {
              const sellUrl = '/?buy_token=';
              navigate(key.user_pubkey + sellUrl + tokenParam);
            } else navigate(`/${key.user_pubkey}/issuance/fungible`);
          } catch {
            // eslint-disable-next-line no-console
            console.log('login error');
          }
        }
        if (subscriptionType === 'LOGOFF') {
          subscription.subscribe().unsubscribe();
          localStorage.removeItem('privKey');
          const key = webapp.create_webapp_keys();
          const pubKeyNew = key.pubKey;
          const privKeyNew = key.privKey;
          localStorage.setItem(
            'privKey',
            Buffer.from(JSON.stringify(privKeyNew)).toString('base64'),
          );
          setPodSubscription(pubKeyNew, privKeyNew);
          const qr = webapp.create_webapp_qr(pubKeyNew);
          setQrJson(qr);
        }
      });
    }
  }, [setPodSubscription, navigate, searchParams, subscription]);

  return (
    <CenteredBox className={styles.loginWrapper}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={LinkMintLogo}
          alt="Linkmint"
          style={{
            position: 'absolute',
            left: 'calc(50% - 110px)',
            marginTop: '40px',
          }}
        />
        {REACT_APP_DEVNET === 'true' && (
          <Typography
            fontSize="20px"
            sx={{
              width: 'fit-content',
              marginTop: '45px',
              marginLeft: '200px',
              color: '#fff',
              fontWeight: 900,
              fontFamily: 'inherit',
            }}
          >
            DEVNET
          </Typography>
        )}
      </div>
      <Typography
        fontSize="14px"
        color="#00E1D4"
        sx={{
          marginTop: '0px',
          fontWeight: 500,
        }}
      >
        Cross-Chain Tokenisation
      </Typography>
      {login ? (
        <div className={styles.admissible}>
          <QRCode
            size={540}
            className={styles.qrWrapper}
            bgColor="transparent"
            fgColor="#fff"
            value={qrJson}
            viewBox="0 0 540 540"
          />
        </div>
      ) : (
        <LoginButtons onLogin={() => setLogin(true)} qr={qrJson} />
      )}
      <Box className={styles.loginBottomBox}>
        <Typography fontSize="14px" className={styles.loginText}>
          Powered by
        </Typography>
        <img src={Logo} alt="Linkmint" className={styles.loginLogo} />
      </Box>
      {REACT_APP_FEATURE_FLAG === 'DEV' && (
        <div className={styles.greenBlock} onClick={handleOpenWebapp}>
          <div className={styles.greenText}>
            <p>Login By Emulating wallet-app</p>
          </div>
        </div>
      )}
      {REACT_APP_FEATURE_FLAG === 'DEV' && (
        <ModalWebapp
          open={openWebapp}
          qrJson={qrJson}
          handleClose={handleCloseWebapp}
        />
      )}
    </CenteredBox>
  );
};
